import "../../style/NewsletterForm.css";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";

function NewsletterForm() {
	const [disableSubmit, setDisableSubmit] = useState(true);
	return (
		<div className="newsletter-form">
			<div className="container">
				<div className="row">
					<div className="col-md-12 newsletter-wrapper">
						<h3>Recevez nos dernières actualités</h3>
						<form>
							<div className="newsletter-input">
								<input
									type="email"
									className="form-control"
									id="exampleInputEmail1"
									aria-describedby="emailHelp"
									placeholder="Email"
								/>
							</div>
							<ReCAPTCHA
								sitekey="6LcttjcfAAAAAIIqIvV-md-KaAUVhc6zxqImi7Dn"
								onChange={async () => {
									setDisableSubmit(false);
								}}
							/>
							<button
								disabled={disableSubmit}
								type="submit"
								className="btn btn-primary btn-newsletter"
								onClick={() => {
									console.log("Newsletter form submitted");
								}}
							>
								S'inscrire
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NewsletterForm;
