import Hero from "./Hero";
import Footer from "../Footer";
import LandingContent from "./LandingContent";
import "../../style/Landing.css";

function Landing() {
	return (
		<div>
			<Hero />
			<LandingContent />
			<Footer />
		</div>
	);
}

export default Landing;
