import "../../style/utils/LoginLinks.css";
import { Link } from "react-router-dom";

function LoginLinks(props) {
	return (
		<div className="links-wrapper">
			<Link className="link-item" to="/">
				Accueil
			</Link>
			<Link className="link-item" to={props.path}>
				{props.text}
			</Link>
		</div>
	);
}

export default LoginLinks;
