import "../../style/Login/Login.css";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

function ForgotPassword() {
	return (
		<div className="login-wrapper">
			<img src={logo} alt="logo" className="login-logo" />
			<h1 className="login-title">Mot de passe oublié ?</h1>
			<form className="login-form">
				<input type="email" className="login-input" placeholder="Email" />
				<Link className="password-forgot password-forgot-retour" to="/login">
					Retour
				</Link>
				<div className="login-button-wrapper">
					<button className="btn-primary login-button password-recup-button">
						Récupérer mon mot de passe
					</button>
				</div>
			</form>
		</div>
	);
}

export default ForgotPassword;
