import "../../style/Register.css";
import logo from "../../assets/images/logo.png";
import RegisterForm from "./RegisterForm";
import LoginLinks from "../utils/LoginLinks";

function Register() {
	return (
		<div className="register-wrapper">
			<img src={logo} alt="logo" className="register-logo" />
			<h1 className="register-title">Inscription</h1>
			<RegisterForm />
			<div className="links-wrapper-login">
				<LoginLinks path="/login" text="Déjà inscrit ?" />
			</div>
		</div>
	);
}

export default Register;
