import "../../style/Explorer/Explorer.css";
import CompaniesList from "./CompaniesList";

function Explorer() {
	return (
		<div className="explorer-wrapper">
			<h1 className="explorer-title">Explorer les entreprises</h1>
			<CompaniesList />
		</div>
	);
}

export default Explorer;
