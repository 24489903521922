import "../style/Header.css";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";

function Header() {
	return (
		<div className="header-wrapper">
			<img src={logo} alt="logo" className="header-logo" />
			<nav className="header-nav">
				<Link to="/" className="header-link">
					Dashboard
				</Link>
				<Link to="/explorer" className="header-link">
					Explorer les entreprises
				</Link>
				<Link to="/" className="header-link">
					Mon compte
				</Link>
			</nav>
		</div>
	);
}

export default Header;
