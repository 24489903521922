import "../style/Footer.css";
import logo from "../assets/images/logo.png";
import twitter from "../assets/images/twitter.png";
import instagram from "../assets/images/instagram.png";
import NewsletterForm from "./utils/NewletterForm";
import { Link } from "react-router-dom";

function Footer() {
	return (
		<footer className="footer">
			<div className="container">
				<div className="row">
					<div className="col-md-4 footer-column">
						<h3>Company Rater</h3>
						<img src={logo} className="logo" alt="Company Rater Logo" />
					</div>
					<div className="col-md-4 footer-column links-column">
						<Link className="footer-link" to="/">
							Nous contacter
						</Link>
						<Link className="footer-link" to="/">
							A propos
						</Link>
						<Link className="footer-link" to="/">
							Mentions légales
						</Link>
						<Link className="footer-link" to="/">
							Politique de confidentialité
						</Link>
					</div>
					<div className="col-md-4 footer-column social-media-column">
						<h3>Nous suivre</h3>
						<Link to="/" className="social-media-link">
							<img
								className="social-media"
								src={twitter}
								alt="social-media"
							></img>
						</Link>
						<Link to="/" className="social-media-link">
							<img
								className="social-media"
								src={instagram}
								alt="social-media"
							></img>
						</Link>
					</div>
					<div>
						<NewsletterForm />
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
