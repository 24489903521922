import { Link } from "react-router-dom";
import heroIimage from "../../assets/images/hero-image.jpg";
import CallToActionBtn from "../utils/CallToActionBtn";

function Hero() {
	return (
		<div className="hero">
			<div className="col-md-6 hero-text-part">
				<h1 className="hero-title">Company Rater</h1>
				<div className="action-wrapper">
					<h2 className="hero-promise">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor
					</h2>
					<CallToActionBtn className="hero-cta" />
				</div>
			</div>
			<div className="col-md-6 hero-image-part">
				<Link className="hero-connexion" to="/login">
					<h3 className="hero-connexion">Connexion</h3>
				</Link>
				<img src={heroIimage} alt="hero" className="hero-image" />
			</div>
		</div>
	);
}

export default Hero;
