import "../../style/Landing.css";
import star from "../../assets/images/star.png";
import company from "../../assets/images/company.png";
import clock from "../../assets/images/clock.png";
import CallToActionBtn from "../utils/CallToActionBtn";

function SiglesList() {
	return (
		<div className="sigles-list">
			<div className="landing-content-item">
				<img className="landing-content-image" src={star} alt="star" />
				<p className="landing-content-text">
					Consultez et enrichissez les notes des entreprises
				</p>
			</div>
			<div className="landing-content-item">
				<img className="landing-content-image" src={company} alt="star" />
				<p className="landing-content-text">
					Visibilité sur toutes les entreprises existant en France
				</p>
			</div>
			<div className="landing-content-item">
				<img className="landing-content-image" src={clock} alt="star" />
				<p className="landing-content-text">
					Des données actualisées en temps réel
				</p>
			</div>
		</div>
	);
}

function LandingMainDisplay() {
	return (
		<div className="landing-main-display">
			<h2 className="body-promise-title">14 jours d'essai offerts</h2>
			<p className="body-promise">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
				tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
				veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
				commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
				velit esse blablabla blabla cillum dolore eu fugiat nulla pariatur
			</p>
			<CallToActionBtn className="body-cta" />
		</div>
	);
}

function LandingContent() {
	return (
		<div className="landing-content">
			<SiglesList />
			<LandingMainDisplay />
		</div>
	);
}

export default LandingContent;
