import "../../style/Login/Login.css";
import logo from "../../assets/images/logo.png";
import LoginLinks from "../utils/LoginLinks";
import { Link } from "react-router-dom";

function Login() {
	return (
		<div className="login-wrapper">
			<img src={logo} alt="logo" className="login-logo" />
			<h1 className="login-title">Connexion</h1>
			<form className="login-form">
				<input type="email" className="login-input" placeholder="E-mail" />
				<input
					type="password"
					className="login-input"
					placeholder="Mot de passe"
				/>
				<LoginLinks path="/register" text="Pas encore inscrit?" />
				<Link className="login-button-wrapper" to="/explorer">
					<button className="btn-primary login-button">Connexion</button>
				</Link>
			</form>
		</div>
	);
}

export default Login;
