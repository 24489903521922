import "../../style/Explorer/Company.css";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";

const Rating = (rate, id) => {
	const rounded = Math.round(rate);
	let stars = [];
	for (let i = 0; i < rounded; i++) {
		stars.push(<AiFillStar key={id + i} className="company-star" />);
	}
	for (let i = rounded; i < 5; i++) {
		stars.push(<AiOutlineStar key={id + i + 8} className="company-star" />);
	}

	return stars;
};

function Company(props) {
	return (
		<div className="company-item">
			<h3 className="company-title">{props.raison_sociale}</h3>
			<div className="company-details">
				<p className="company-details-text">
					Date de création : {props.date_de_creation}
				</p>
				<p className="company-details-text">Taille : {props.taille} employés</p>
				<p className="company-details)-text">
					Département: {props.département}
				</p>
			</div>
			<div className="rating-wrapper">
				<p className="rating-text">{props.note}/5</p>
				<div className="rating-stars-wrapper">
					{Rating(props.note, props.id)}
				</div>
			</div>
		</div>
	);
}

export default Company;
