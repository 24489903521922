import companies from "../../data/companies.json";
import "../../style/Explorer/CompaniesList.css";
import Company from "./Company";

function CompaniesList() {
	return (
		<div className="companies-list">
			{companies.map((company) => (
				<Company key={company.id} {...company} />
			))}
		</div>
	);
}

export default CompaniesList;
