import "../../style/Register.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
	email: Yup.string()
		.email("Veuillez entrer un email valide")
		.required("Ce champ est requis"),
	password: Yup.string()
		.required("Ce champ est requis")
		.min(8, "Votre mot de passe doit contenir au moins 8 caractères"),
	confirmPassword: Yup.string()
		.oneOf(
			[Yup.ref("password"), null],
			"Les mots de passe ne correspondent pas"
		)
		.required("Ce champ est requis"),
	acceptConditions: Yup.boolean()
		.required("Veuillez accepter les conditions d'utilisation")
		.oneOf([true], "Veuillez accepter les conditions d'utilisation"),
});

const initialValues = {
	email: "",
	password: "",
	confirmPassword: "",
	acceptConditions: false,
};

const handleSubmit = (values) => {
	console.log(values);
};

function RegisterForm() {
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => handleSubmit(values)}
		>
			<div className="register-form-wrapper">
				<Form className="register-form">
					<div className="register-form-input-wrapper">
						<label className="register-form-label">E-mail</label>
						<Field
							type="email"
							id="email"
							name="email"
							className="register-form-input"
						/>
						<ErrorMessage
							name="email"
							component="small"
							className="text-danger"
						/>
					</div>
					<div className="register-form-input-wrapper">
						<label className="register-form-label">Mot de passe</label>
						<Field
							type="password"
							id="password"
							name="password"
							className="register-form-input"
						/>
						<ErrorMessage
							name="password"
							component="small"
							className="text-danger"
						/>
					</div>
					<div className="register-form-input-wrapper">
						<label className="register-form-label">
							Confirmer le mot de passe
						</label>
						<Field
							type="password"
							id="confirmPassword"
							name="confirmPassword"
							className="register-form-input"
						/>
						<ErrorMessage
							name="confirmPassword"
							id="confirmPassword"
							component="small"
							className="text-danger"
						/>
					</div>
					<div className="conditions">
						<label className="register-form-label" htmlFor="conditions">
							J'ai lu et j'accepte les conditions d'utilisation
						</label>
						<Field
							type="checkbox"
							name="acceptConditions"
							id="conditions"
							className="checkbox-input"
						/>
						<ErrorMessage
							name="acceptConditions"
							component="small"
							className="text-danger"
						/>
					</div>
					<button type="submit" className="register-form-btn btn-primary">
						S'inscrire
					</button>
				</Form>
			</div>
		</Formik>
	);
}

export default RegisterForm;
