import Explorer from "../components/Explorer/Explorer";
import Footer from "../components/Footer";
import Header from "../components/Header";

function ExplorerUi() {
	return (
		<div className="explorer-ui">
			<Header />
			<Explorer />
			<Footer />
		</div>
	);
}

export default ExplorerUi;
