import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Landing from "./components/landing/Landing";
import Login from "./components/Login/Login";
import ForgotPassword from "./components/Login/ForgotPassword";
import Register from "./components/Register/Register";
import "./style/App.css";
import ExplorerUi from "./views/ExplorerUI";

function App() {
	return (
		<Router>
			<Routes>
				<Route exact path="/" element={<Landing />} />
				<Route exact path="/login" element={<Login />} />
				<Route exact path="/forgotpassword" element={<ForgotPassword />} />
				<Route exact path="/register" element={<Register />} />
				<Route exact path="/explorer" element={<ExplorerUi />} />
			</Routes>
		</Router>
	);
}

export default App;
