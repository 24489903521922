import { Link } from "react-router-dom";
import "../../style/App.css";

function CallToActionBtn() {
	return (
		<Link to="register">
			<button className="btn-cta">M'inscrire</button>
		</Link>
	);
}

export default CallToActionBtn;
